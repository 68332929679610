import React, { useState } from 'react';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import FingoTable from '@fingo/lib/components/tables/FingoTable';
import { PaperHeader } from '@fingo/lib/components/headers';
import { useGetCountryFromUrl } from '@fingo/lib/hooks';
import Typography from '@mui/material/Typography';
import { useMasterEntityPreColumns } from '@fingo/lib/constants';
import { FACTORING_TRANSFER_MASTER_ENTITY_COLUMNS, FACTORING_TRANSFER_MASTER_ENTITY_HEADERS } from '../../constants/treasury';
import { TRANSFERS_MASTER_ENTITIES } from '../../graphql/queries/transfer';
import FactoringTransferInvoices from './FactoringTransferInvoice';
import TransferActions from './actions/TransferActions';

const TransfersList = () => {
  const country = useGetCountryFromUrl();
  const [transferRestrictionsReady, setTransferRestrictionsReady] = useState(false);

  return (
    <FingoMainView
      id="transfer-panel"
      query={TRANSFERS_MASTER_ENTITIES}
      queryCustomVariables={{
        receiverId: null,
        companyId: null,
        receiver_Rut: null,
        dateIssued_Gte: null,
        dateIssued_Lte: null,
        countryId: country?.id,
        transferRestrictionsReady,
      }}
      slots={{
        header: PaperHeader,
        table: FingoTable,
        actions: TransferActions,
      }}
      slotProps={{
        header: {
          viewTitle: 'Empresas con giros pendientes',
          finder: {
            searchPlaceHolder: 'Búsqueda por empresa',
          },
        },
        table: {
          includeHeaders: FACTORING_TRANSFER_MASTER_ENTITY_HEADERS,
          columns: useMasterEntityPreColumns(FACTORING_TRANSFER_MASTER_ENTITY_COLUMNS),
          checkboxSelection: false,
          isRowSelectable: () => true,
          collapsible: true,
          isRowCollapsible: () => true,
          collapseComponent: ({ row }) => (
            <FactoringTransferInvoices
              company={row.company}
            />
          ),
          disableSelectionOnClick: true,
          noRowsMessage: () => <Typography>No hay giros pendientes</Typography>,
          rowsPerPageOptions: [30, 50, 75, 100],
        },
        actions: {
          transferRestrictionsReady,
          setTransferRestrictionsReady,
        },
      }}
    />
  );
};

export default TransfersList;
