import React, { useCallback, useMemo, useState } from 'react';
import { PropTypes } from 'prop-types';
import IconButtonsStack from '@fingo/lib/components/stacks/IconButtonsStack';
import { useCompanyRequirementsStates } from '@fingo/lib/hooks';
import Assignment from '@mui/icons-material/Assignment';
import Extension from '@mui/icons-material/Extension';
import NewReleases from '@mui/icons-material/NewReleases';
import useFetchPresignedUrl from '@fingo/lib/hooks/useFetchPresignedUrl';
import useTheme from '@mui/material/styles/useTheme';
import RiskRestrictionsDialog from './dialogs/RiskRestrictionsDialog';
import { calculateBFStatus, buildComplianceOperationDetailUrl, riskRestrictionRequirementsIconColor } from '../../helpers/treasury';

const TransferCompanyRequirements = ({ masterEntity, disabled, filterRestriction }) => {
  const [,, fetchGivenId] = useFetchPresignedUrl();
  const [openRiskRestrictionsDialog, setOpenRiskRestrictionsDialog] = useState(false);
  const openUrl = useCallback((model) => fetchGivenId(model.globalAppId), [fetchGivenId]);
  const { company, compliance, contract, hasMultipleOperations,
    filteredRestrictions, BFStatus, regcheqId,
  } = useCompanyRequirementsStates(masterEntity, filterRestriction);
  const theme = useTheme();
  const SUCCESS = theme.palette.success.main;
  const ERROR = theme.palette.error.secondary;

  const actualBFStatus = useMemo(
    () => calculateBFStatus(BFStatus, hasMultipleOperations, compliance),
    [BFStatus, hasMultipleOperations, compliance],
  );

  const requirements = [
    {
      id: 'contract',
      tooltipTitle: 'Contrato',
      disabled: !contract,
      color: contract ? SUCCESS : ERROR,
      onClick: () => openUrl(contract),
      icon: <Assignment />,
    },
    {
      id: 'regcheq',
      tooltipTitle: actualBFStatus?.tooltip,
      disabled: !regcheqId,
      color: actualBFStatus?.color || theme.palette.action.disabled,
      onClick: () => window.open(buildComplianceOperationDetailUrl(regcheqId)),
      icon: <Extension />,
    },
    {
      id: 'risk',
      tooltipTitle: 'Riesgo',
      disabled: !filteredRestrictions.length,
      color: riskRestrictionRequirementsIconColor(filteredRestrictions),
      onClick: () => setOpenRiskRestrictionsDialog(!openRiskRestrictionsDialog),
      icon: <NewReleases />,
    },
  ];

  return (
    <>
      <IconButtonsStack items={requirements} />
      <RiskRestrictionsDialog
        open={openRiskRestrictionsDialog}
        handleClose={() => setOpenRiskRestrictionsDialog(false)}
        companyId={company?.id}
        restrictions={filteredRestrictions}
        disabled={disabled}
      />
    </>
  );
};

TransferCompanyRequirements.propTypes = {
  masterEntity: PropTypes.shape({
    company: PropTypes.shape({
      id: PropTypes.string.isRequired,
      rut: PropTypes.string.isRequired,
      companyevaluationrestrictionsSet: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          restriction: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          status: PropTypes.string.isRequired,
          resolutionExplanation: PropTypes.string.isRequired,
        }),
      ).isRequired,
      documents: PropTypes.arrayOf(
        PropTypes.shape({
          documentType: PropTypes.string.isRequired,
          exists: PropTypes.bool.isRequired,
          lastDate: PropTypes.string,
          lastFile: PropTypes.string,
        }),
      ).isRequired,
    }).isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
  filterRestriction: PropTypes.oneOf(['Risk', 'Operations']),
};

TransferCompanyRequirements.defaultProps = {
  disabled: false,
  filterRestriction: null,
};

export default TransferCompanyRequirements;
