import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { useSnackBars } from '@fingo/lib/hooks';
import { INVOICE_TRANSFER_INFO,
  RESOLVE_COMPANY_EVALUATION_RESTRICTIONS,
  GET_COMPANY_EVALUATIONS,
  GET_COMPANY_ORDERING_EVALUATIONS,
  CHANGE_COMPANY_EVALUATION_RESTRICTION_STATUS,
  MY_ASSIGNMENTS,
  GET_MASTER_ENTITY_RESTRICTIONS,
} from '@fingo/lib/graphql';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DataGrid, GridCellModes } from '@mui/x-data-grid';
import { LoadingButton } from '@mui/lab';
import { ThreeActionsButtonsCell } from '@fingo/lib/components/cells';
import { InformationTooltip } from '@fingo/lib/components/tooltips';
import { GET_PREOFFERS_REQUESTS } from '../../../../risk/graphql/default/GetPreoffersRequestsQuery';
import { RISK_RESTRICTION_COLUMNS } from '../../../constants/treasury';
import { restrictionRequiresFile, findCompanyRiskRestrictionsDocument } from '../../../helpers/treasury';
import { RiskRestrictionDocumentCell } from '../cells';

const RiskRestrictionsDialog = ({
  open,
  handleClose,
  restrictions,
  companyId,
  disabled,
  resolveRestrictionResolutionRefetchQueries,
}) => {
  const { data } = useQuery(GET_MASTER_ENTITY_RESTRICTIONS, {
    variables: { masterEntityId: companyId },
  });
  const company = data?.getMasterEntity?.company || {};

  const [cellModesModel, setCellModesModel] = useState({});
  const [rows, setRows] = useState(
    restrictions.map((restriction) => ({
      ...restriction,
    })),
  );
  const { addAlert } = useSnackBars();

  // Commercial
  const isResolutionReady = (row) => {
    let needsDocument = false;
    if (restrictionRequiresFile(row.restriction)) {
      needsDocument = true;
      const companyDocument = findCompanyRiskRestrictionsDocument(
        company.documents,
        row.restriction,
      );
      if (companyDocument.lastFile) {
        needsDocument = false;
      }
    }
    return (
      row.resolutionExplanation !== ''
      && !needsDocument
      && row.resolutionExplanation?.length <= 400
    );
  };

  // Commercial
  const [resolveCompanyRestriction,
    { loading }] = useMutation(RESOLVE_COMPANY_EVALUATION_RESTRICTIONS, {
    variables: {
      restrictions: rows.filter(isResolutionReady)
        .map(
          ({
            id,
            restriction,
            description,
            resolutionExplanation,
          }) => ({
            id,
            restriction,
            description,
            resolutionExplanation,
          }),
        )
        .sort((a, b) => Number(a) - Number(b)),
    },
    onCompleted: () => {
      handleClose();
      addAlert({
        id: 'resolve-restrictions',
        message: 'Se resolvieron las restricciones correctamente!',
      });
    },
    onError: () => {
      addAlert({
        id: 'resolve-restrictions-error',
        message: 'Hubo un error, comunícate con el equipo de desarrollo.',
      });
    },
    refetchQueries: [
      INVOICE_TRANSFER_INFO,
      GET_COMPANY_EVALUATIONS,
      GET_COMPANY_ORDERING_EVALUATIONS,
      MY_ASSIGNMENTS,
      GET_COMPANY_EVALUATIONS,
    ],
  });

  // Treasury and Risk
  const [approveCompanyRestrictionResolution] = useMutation(
    CHANGE_COMPANY_EVALUATION_RESTRICTION_STATUS,
    {
      onError: () => {
        addAlert({
          id: 'change-restriction-status-error',
          message: 'Hubo un error, comunícate con el equipo de desarrollo.',
        });
      },
      refetchQueries: [
        ...resolveRestrictionResolutionRefetchQueries,
        GET_COMPANY_EVALUATIONS,
        GET_PREOFFERS_REQUESTS,
      ],
    },
  );

  // Treasury and Risk
  const handleApproveCompanyRestrictionResolution = ({ id, action }) => {
    approveCompanyRestrictionResolution({
      variables: {
        restriction: id,
        action,
      },
      onCompleted: () => {
        setRows((prevRows) => prevRows.filter((row) => row.id !== id));
        addAlert({
          id: 'change-restriction-status',
          message: 'Se actualizó el estado de la restricción correctamente!',
        });
      },
    });
  };

  // Commercial
  const longResolutionExplanation = useMemo(() => rows.some(
    ((row) => row.resolutionExplanation?.length > 400),
  ), [rows]);
  const isAnyResolutionReady = useMemo(() => rows.some(isResolutionReady), [rows]);

  const handleCellEditCommit = ({ id, field, value }) => {
    setRows(rows.map((row) => (row.id === id ? { ...row, [field]: value } : row)));
    setCellModesModel({ [id]: { [field]: { mode: GridCellModes.View } } });
  };

  // Commercial, Treasury and Risk
  const columns = [
    ...RISK_RESTRICTION_COLUMNS,
    {
      field: 'archivo',
      headerName: 'Archivo',
      headerAlign: 'center',
      align: 'center',
      width: 130,
      sortable: false,
      renderHeader: () => (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="body1" fontWeight="500">
            Archivo
          </Typography>
          {disabled && (
          <InformationTooltip
            title={`Aquí se despliega el último archivo cargado. Asegurate de subir 
            el archivo correcto antes de enviar la resolución.`}
            size="small"
          />
          )}
        </Stack>
      ),
      renderCell: ({ row }) => (
        <RiskRestrictionDocumentCell
          row={row}
          company={company}
        />
      ),
    },
  ];

  // Treasury and Risk
  if (!disabled) {
    columns.push({
      field: 'acceptEvaluationResolution',
      headerName: 'Aprobar resolución',
      headerAlign: 'center',
      align: 'center',
      width: 150,
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => (
        <ThreeActionsButtonsCell
          onAccept={() => handleApproveCompanyRestrictionResolution({ id: row.id,
            action: 'accept' })}
          onReject={() => handleApproveCompanyRestrictionResolution({ id: row.id,
            action: 'reject' })}
          onDelete={() => handleApproveCompanyRestrictionResolution({ id: row.id,
            action: 'bypass' })}
          sx={{ justifyContent: 'center' }}
        />
      ),
    });
  }

  return (
    <FingoDialog
      open={open}
      handleClose={() => handleClose()}
      maxWidth="lg"
      fullWidth
      title={`Restricciones de riesgo de ${company?.name}`}
      dialogActionButton={disabled && (
        <Tooltip title={
          !isAnyResolutionReady ? 'Se debe contestar y cargar los documentos correspondientes para al menos una restricción' : ''
          }
        >
          <Stack mr={0} justifyContent="right">
            {longResolutionExplanation
            && (
            <Typography variant="body1" sx={{ color: 'red' }}>
              ¡No pueden haber resoluciones con más de 400 caracteres!
            </Typography>
            )}
            <LoadingButton
              disabled={!isAnyResolutionReady}
              onClick={resolveCompanyRestriction}
              loading={loading}
            >
              Enviar resoluciones
            </LoadingButton>
          </Stack>
        </Tooltip>
      )}
    >
      <div style={{ height: 365, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          cellModesModel={cellModesModel}
          onCellEditCommit={handleCellEditCommit}
        />
      </div>
    </FingoDialog>
  );
};

RiskRestrictionsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  restrictions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      restriction: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    }),
  ).isRequired,
  companyId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  resolveRestrictionResolutionRefetchQueries: PropTypes.arrayOf(PropTypes.string),
};

RiskRestrictionsDialog.defaultProps = {
  disabled: false,
  resolveRestrictionResolutionRefetchQueries: [],
};

export default RiskRestrictionsDialog;
