import { useMutation } from '@apollo/client';
import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { useInvoiceLucilaCustomColumns, useInvoicePreColumns } from '@fingo/lib/constants';
import { CREATE_REINTEGRATION, INVOICE_AMOUNT_DETAILS, INVOICE_TRANSFER_INFO } from '@fingo/lib/graphql';
import { formatMoney, addMoneyWithCurrency, subtractMoneyWithCurrency } from '@fingo/lib/helpers';
import { useBooleanState, useFilteredQuery, useInvoices, useSnackBars } from '@fingo/lib/hooks';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';

const COLUMNS = [
  'folio',
  'dateIssued',
  'receiver_Name',
  'receiver_Rut',
  'amountWithIva',
];

const ReintegrationDialog = ({ selectedDocumentIds, cleanDocuments }) => {
  const [openDialog, toggleDialog,, closeDialog] = useBooleanState();
  const { invoices } = useInvoices(INVOICE_TRANSFER_INFO, {
    skip: !selectedDocumentIds?.length,
    variables: { id_In: selectedDocumentIds },
  });
  const disabled = useMemo(
    () => !invoices?.length
      || [...new Set(invoices.map((oc) => oc.company.id))].length > 1
      || !invoices[0].company?.masterEntity?.company?.pendingReintegrations,
    [invoices],
  );
  const { addAlert } = useSnackBars();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [selectedInvoicesForReintegrationIds,
    setSelectedInvoicesForReintegrationIds] = useState([]);

  const handleClose = useCallback(() => {
    setSelectedInvoicesForReintegrationIds([]);
    closeDialog();
  }, []);

  const companyId = invoices[0]?.company.id;
  const [handleCreateReintegrations, createReintegrationResult] = useMutation(
    CREATE_REINTEGRATION,
    {
      variables: { owedInvoicesIds: selectedInvoicesForReintegrationIds,
        payingInvoicesIds: selectedDocumentIds },
      onCompleted: () => {
        setSelectedInvoicesForReintegrationIds([]);
        closeDialog();
        cleanDocuments();
        addAlert({
          id: 'invoices-reintegrated',
          message: 'Facturas Reintegradas',
        });
      },
      onError: () => {
        closeDialog();
        addAlert({
          id: 'invoices-reintegrated',
          message: 'Ha ocurrido algún error',
        });
      },
      refetchQueries: [INVOICE_TRANSFER_INFO, INVOICE_AMOUNT_DETAILS],
    },
  );
  const {
    data: rawData,
    loading,
  } = useFilteredQuery(
    INVOICE_AMOUNT_DETAILS,
    {
      variables: { first: 100,
        offset: 0,
        receiver_Rut: null,
        receiverId: null,
        companyId,
        dateIssued_Gte: null,
        dateIssued_Lte: null,
        availableForReintegration: true,
      },
      skip: !companyId,
      notifyOnNetworkStatusChange: true,
    },
  );
  const selectedForReintegration = rawData?.invoices?.edges?.map((edge) => edge.node).filter(
    (inv) => selectedInvoicesForReintegrationIds.includes(inv.id),
  );
  const getTotalAmountFromReintegration = () => {
    const total = invoices?.reduce(
      (acc, inv) => addMoneyWithCurrency(
        acc,
        inv.paymentDiscounts.paymentAmountAfterNegativeSurplus,
      ),
      0,
    ) || 0;
    const totalReintegration = selectedForReintegration?.reduce(
      (acc, inv) => addMoneyWithCurrency(acc, inv.amountWithIva),
      0,
    ) || 0;
    return subtractMoneyWithCurrency(total, totalReintegration);
  };

  const totalAmountFromReintegration = useMemo(
    () => getTotalAmountFromReintegration(),
    [invoices, selectedForReintegration],
  );
  const rowCount = rawData?.invoices.totalCount || 0;
  return (
    <>
      <Button
        color="primary"
        variant="contained"
        size="small"
        disabled={disabled}
        disableElevation
        onClick={toggleDialog}
        id="toggle-download-portfolio"
      >
        Reintegrar
      </Button>
      <FingoDialog
        open={openDialog}
        handleClose={handleClose}
        maxWidth="md"
        fullWidth
        title="Reintegros de facturas"
      >
        <FingoDataGrid
          rows={rawData?.invoices?.edges?.map((edge) => edge.node) || []}
          columns={useInvoicePreColumns(useInvoiceLucilaCustomColumns()).filter(
            (col) => COLUMNS.includes(col.field),
          )}
          loadingWithSkeleton={loading}
          pagination
          paginationMode="server"
          onPageChange={setPage}
          page={page}
          pageSize={pageSize}
          rowCount={rowCount}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          checkboxSelection
          disableSelectionOnClick
          onSelectionModelChange={(ids) => setSelectedInvoicesForReintegrationIds(ids)}
          selectionModel={selectedInvoicesForReintegrationIds}
          density="compact"
          maxHeight={500}
        />
        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box>
            <Typography variant="h2">
              Monto disponible para reintegro:
            </Typography>
            <Typography variant="h1">
              ${
              formatMoney(totalAmountFromReintegration)
              }
            </Typography>

          </Box>
          <Box
            justifyContent="right"
            display="flex"
          >
            <LoadingButton
              color="primary"
              variant="contained"
              size="small"
              loading={createReintegrationResult.loading}
              disableElevation
              disabled={selectedInvoicesForReintegrationIds.length === 0
                || (totalAmountFromReintegration
                 && totalAmountFromReintegration.amount <= 0)}
              id="toggle-download-portfolio"
              onClick={handleCreateReintegrations}
            >
              Crear reintegros
            </LoadingButton>
          </Box>
        </Stack>
      </FingoDialog>
    </>
  );
};

ReintegrationDialog.propTypes = {
  selectedDocumentIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  cleanDocuments: PropTypes.func.isRequired,
};

export default ReintegrationDialog;
