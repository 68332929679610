import React, { useState } from 'react';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import Tooltip from '@mui/material/Tooltip';
import { useBooleanState, useSnackBars } from '@fingo/lib/hooks';
import UploadDropZoneDialogContainer from '@fingo/lib/components/containers/UploadDropZoneDialogContainer';
import { UPLOAD_BANK_STATEMENT } from '@fingo/lib/graphql';
import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';

const UploadBankStatementsDialog = () => {
  const [files, setFiles] = useState([{ name: '' }]);
  const [open, toggleOpen] = useBooleanState();
  const country = useGetCountryFromUrl();
  const { addAlert } = useSnackBars();

  const handleClose = () => {
    toggleOpen(false);
    setFiles([{ name: '' }]);
  };
  const [uploadMutation, { loading }] = useMutation(UPLOAD_BANK_STATEMENT, {
    variables: { file: files[0], countryId: country.id },
    onCompleted: handleClose,
    onError: (err) => addAlert({
      id: 'delete-control-group',
      message: err.message,
    }),
  });

  return (
    <>
      <Tooltip title="Subir archivo .xlsx con cartola bancaria">
        <AccountBalanceIcon
          color="primary"
          onClick={toggleOpen}
          sx={{ cursor: 'pointer' }}
        />
      </Tooltip>
      <FingoDialog
        title="Subir archivo .xslx"
        open={open}
        handleClose={handleClose}
        dialogActionButton={(
          <LoadingButton
            key="upload-bank-statement-button"
            size="small"
            color="primary"
            variant="contained"
            onClick={uploadMutation}
            loading={loading}
            disabled={files[0].name === ''}
          >
            Subir cartola
          </LoadingButton>
        )}
      >
        <UploadDropZoneDialogContainer
          key="upload-bank-statement"
          files={files}
          setFiles={setFiles}
        />
      </FingoDialog>
    </>
  );
};

export default UploadBankStatementsDialog;
