import { DocumentList } from '@fingo/lib/components/lists';
import { MOBILE_HEADERS } from '@fingo/lib/constants';
import { INVOICE_LOAN_TRANSFER_INFO } from '@fingo/lib/graphql';
import React from 'react';
import { LOANS_COLUMNS, LOANDS_HEADERS } from '../../constants/treasury';

const InvoiceLoanTransfersList = () => (
  <DocumentList
    trackerId="LoansTransfers"
    type="loans-transfer-panel"
    headerTitle="Devolución de Facturas a Fondo"
    queryDocument={INVOICE_LOAN_TRANSFER_INFO}
    includeHeaders={LOANDS_HEADERS}
    mobileHeaders={MOBILE_HEADERS}
    showExportInvoice
    preColumns={LOANS_COLUMNS}
    density="compact"
    initialOrderBy="company_MasterEntity_Name"
    onCompletedSetRows={(data) => data.invoiceLoansMoneyTransfers.edges.map((edge) => (edge.node))}
    onCompletedSetLength={(data) => data.invoiceLoansMoneyTransfers.totalCount}
  />
);

export default InvoiceLoanTransfersList;
