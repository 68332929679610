import React, { useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import RemoveIcon from '@mui/icons-material/Remove';
import DocumentChip from '@fingo/lib/components/cells/DocumentsChip';
import useUploadCompanyDocument from '@fingo/lib/hooks/useUploadCompanyDocument';
import {
  findCompanyRiskRestrictionsDocument,
  restrictionRequiresFile,
  riskRestrictionToDocumentType,
} from '../../../helpers/treasury';

const RiskRestrictionDocumentCell = ({ row, company }) => {
  const inputRef = useRef();
  const companyDocument = findCompanyRiskRestrictionsDocument(
    company.documents,
    row.restriction,
  );
  const [documentToUpload, setDocumentToUpload] = useState({});
  const { submit, loading } = useUploadCompanyDocument();

  const handleOpenInput = (document) => {
    setDocumentToUpload(document);
    inputRef.current.click();
  };

  const handleChange = (event) => {
    const file = event.target.files[0];
    submit({
      companyId: company.id,
      documentType: documentToUpload.documentType,
      file,
    });
  };

  if (restrictionRequiresFile(row.restriction)) {
    return (
      <DocumentChip
        documentToUpload={{
          documentType: riskRestrictionToDocumentType(row.restriction),
        }}
        inputRef={inputRef}
        document={companyDocument}
        handleChange={handleChange}
        handleOpenInput={handleOpenInput}
        url={companyDocument.lastFile || ''}
        loading={loading}
      />
    );
  }
  return <RemoveIcon />;
};

RiskRestrictionDocumentCell.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          code: PropTypes.string,
          shortName: PropTypes.string,
        }).isRequired,
        url: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
  row: PropTypes.shape({
    description: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    resolutionExplanation: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    restriction: PropTypes.string.isRequired,
  }).isRequired,
};

export default RiskRestrictionDocumentCell;
