import React from 'react';
import { PropTypes } from 'prop-types';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

const TransferRestrictionsReadyFilter = ({ filter, setFilter }) => (
  <>
    <Switch
      variant="contained"
      color="success"
      onChange={() => { setFilter(!filter); }}
    />
    <Typography variant="h6">Requerimientos Listos</Typography>
  </>
);

TransferRestrictionsReadyFilter.propTypes = {
  filter: PropTypes.bool.isRequired,
  setFilter: PropTypes.func.isRequired,
};

export default TransferRestrictionsReadyFilter;
