import React from 'react';
import { PropTypes } from 'prop-types';
import TransferRestrictionsReadyFilter from './TransferRestrictionsReadyFilter';

const TransferActions = ({ transferRestrictionsReady, setTransferRestrictionsReady }) => (
  // All Transfer Actions
  <>
    <TransferRestrictionsReadyFilter
      filter={transferRestrictionsReady}
      setFilter={setTransferRestrictionsReady}
    />
  </>
);
TransferActions.propTypes = {
  transferRestrictionsReady: PropTypes.bool.isRequired,
  setTransferRestrictionsReady: PropTypes.func.isRequired,
};

export default TransferActions;
