import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { MoneyType } from '@fingo/lib/propTypes';
import { invoiceAmounts } from '../../helpers/treasury';

const TransferAmountSummary = ({ invoice }) => (
  <Stack alignItems="flex-start" justifyContent="center" width="100%">
    {invoiceAmounts(invoice).map((item) => (
      <Stack
        key={item.title}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        spacing={1}
      >
        <Typography variant="body2" noWrap width="max-content%">
          {item.title}:
        </Typography>
        <Typography variant="body2" noWrap width="max-content" textAlign="right">
          {item.value}
        </Typography>
      </Stack>
    ))}
  </Stack>
);

TransferAmountSummary.propTypes = {
  invoice: PropTypes.shape({
    amountWithIva: MoneyType.isRequired,
    paymentDiscounts: PropTypes.shape({
      retentionAssociated: MoneyType.isRequired,
      interestAsociated: MoneyType.isRequired,
      commissionAsociated: MoneyType.isRequired,
      orderingAmountAssociated: MoneyType.isRequired,
      reintegrationAmountAssociated: MoneyType.isRequired,
      replacementAmountAssociated: MoneyType.isRequired,
      negativeSurplusAsociated: MoneyType.isRequired,
    }).isRequired,
    offer: PropTypes.shape({
      defaultRate: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};

export default TransferAmountSummary;
